import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

function Cookies() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
        <li onClick={handleShow}>
            Politica de utilizare a cookie-urilor
        </li>

        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            size="lg"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Politica de utilizare a cookie-urilor</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p><strong>DATE DE IDENTIFICARE</strong></p>
                <p><strong>Lalecris Sewerage Serv S.R.L</strong></p>
                <p>Adresa: Strada Gherghiței, Str. Gherghitei nr 80, București 022514</p>
                <p>CUI: 41492431</p>
                <p>Reg. Com.:J40/10372/2019</p>
                <p>Email : office@vidanjareconstanta.ro</p>
                <p><strong>Ce sunt Cookie-urile?</strong></p>
                <p>Cookie-ul este un fisier de mici dimensiuni, format din litere si numere, care va fi stocat pe computerul, terminalul mobil sau alte echipamente ale unui utilizator de pe care se acceseaza internetul. Cookie-ul este instalat prin solicitarea emisa de catre un web-server unui browser (ex: Internet Explorer, Chrome) si este complet pasiv (nu contine programe software, virusi sau spyware si nu poate accesa informatiile de pe hard driverul utilizatorului).</p>
                <p><strong>La ce sunt folosite Cookie-urile?</strong></p>
                <p>Aceste fisiere fac posibila recunoasterea terminalului utilizatorului si prezentarea continutului intr-un mod relevant, adaptat preferintelor utilizatorului. Cookie-urile asigura utilizatorilor o experienta placuta de navigare si sustin eforturile nostre pentru a oferi servicii comfortabile utilizatorilor.</p>
                <p><strong>Ce Cookie-uri folosim?</strong></p>
                <p>Folosim doua tipuri de Cookie-uri: per sesiune si fixe. Cookie-urilke per sesiune sunt fisiere temporare ce raman in terminalul utilizatorului pana la terminarea sesiunii sau inchiderea aplicatiei (browser-ului web). Fisierele fixe raman pe terminalul utilizatorului pe o perioada in parametrii Cookie-ului sau pana sunt sterse manual de utilizator.</p>
                <p>Exemple de cookie-uri utilizate de www vidanjareconstanta.ro</p>
		
                <table border="1px dotted #f1f1f1;" width="100%">
                    <tbody>
                        <tr>
                            <td width="185"><strong>COOKIE</strong></td>
                            <td width="95"><strong>FURNIZOR</strong></td>
                            <td width="225"><strong>SCOPUL GENERAL</strong></td>
                            <td width="147"><strong>VALABILITATE COOKIE</strong></td>
                        </tr>
                        <tr>
                            <td width="185"><strong>_gat_gtag_UA_</strong> &nbsp;</td>
                            <td width="95">Google Analytics</td>
                            <td width="225">Acest cookie colecteaza informatii despre maniera in care utilizatorii utilizeaza pagina web.</td>
                            <td width="147">1 minut</td>
                        </tr>
                        <tr>
                            <td width="185"><strong>_ga</strong> &nbsp;</td>
                            <td width="95">Google Analytics</td>
                            <td width="225">Acest cookie este utilizat pentru diferentierea utilizatorilor. Acesta se actualizeaza ori de cate ori datele sunt trimise prin Google Analytics.</td>
                            <td width="147">2 ani</td>
                        </tr>
                        <tr>
                            <td width="185"><strong>_gid</strong> &nbsp;</td>
                            <td width="95">Google Analytics</td>
                            <td width="225">Acest cookie este utilizat pentru diferentierea utilizatorilor. Acesta se actualizeaza ori de cate ori datele sunt trimise prin Google Analytics.</td>
                            <td width="147">24 ore</td>
                        </tr>
                        <tr>
                            <td width="185">&nbsp; &nbsp;</td>
                            <td width="95">&nbsp;</td>
                            <td width="225">&nbsp;</td>
                            <td width="147">&nbsp;</td>
                        </tr>
                    </tbody>
                </table>
                <p>Pentru informatii complete referitoare la fisierele de tip cookie, puteti vizita www.allaboutcookies.org.</p>
                <p>Pentru ce scopuri sunt utilizate cookie-urile prin intermediul paginilor noastre de internet?</p>
                <p>Cookie-urile sunt folosite pentru a furniza utilizatorilor acestor website-uri o experienta mai buna de navigare si servicii adaptate nevoilor si interesului fiecarui utilizator in parte, precum:</p>
                <p>- Personalizarea anumitor setari precum limba in care este vizualizat un site, moneda in care sunt exprimate preturile si tarifele, pastrarea optiunilor pentru diverse produse / servicii in cosul de cumparaturi / formulare.</p>
                <p>- Imbunatatirea utilizarii website-urilor, inclusiv prin identificarea anumitor erori care apar in timpul vizitarii / utilizarii acestora de catre utilizatori.</p>
                <p>- Furnizarea de statistici anonime cu privire la modul in care sunt folosite aceste pagini de internet de catre vidanjareconstanta.ro, in calitate de detinator al acestora.</p>
                <p>-Anticiparea anumitor bunuri / servicii care vor fi puse la dispozitia utilizatorilor in viitor prin intermediul acestor pagini de internet.</p>
                <p>Cum sunt folosite aceste cookie-uri de catre Website-urile noastre?</p>
                <p>Prin utilizarea / vizitarea paginii de internet pot fi plasate urmatoarele cookie-uri:</p>
                <p>- Cookie-uri necesare sau cookie-uri de performanta a website-urilor</p>
                <p>- Cookie-uri de analiza / statistica a utilizatorilor</p>
                <p>- Cookie-uri pentru geotargeting</p>
                <p>- Cookie-uri de inregistrare</p>
                <p>- Cookie-uri de marketing / publicitate</p>
                <p><strong>Cookie-uri necesare sau cookie-urile de performanta a website-urilor</strong></p>
                <p>Prin aceste cookie-uri sunt memorate preferintele utilizatorului acestor pagini de internet, astfel incat nu mai este nevoie de setarea lor la fiecare vizita a site-ului.</p>
                <p><strong>Cookie-uri de analiza / statistica a utilizatorilor</strong></p>
                <p>Acestea ne informeaza daca un utilizator al paginilor noastre de internet a mai vizitat aceste pagini in trecut. Cookie-urile respective sunt utilizate doar in scopuri statistice.</p>
                <p>Daca ai fost inregistrat anterior putem cunoaste detaliile pe care ni le-ai furnizat precum adresa de email sau numele de utilizator &ndash; acestea sunt supuse confidentialitatii si prevederilor din Politica de confidentialitate precum si prevederilor legislatiei in vigoare cu privire la protejarea datelor cu caracter personal.</p>
                <p><strong>Cookie-uri pentru geotargeting</strong></p>
                <p>Acestea sunt utilizate de catre un soft care stabileste tara de provenienta a utilizatorului paginilor de internet. Vor fi primite aceleasi reclame indiferent de limba selectata.</p>
                <p><strong>Cookie-uri de inregistrare</strong></p>
                <p>Cand te inregistrezi in website-urile noastre, generam un cookie care ne anunta daca esti inregistrat sau nu. Serverele noastre folosesc aceste cookie-uri pentru a ne arata contul cu care esti inregistrat si daca ai permisiunea pentru un serviciu anume.</p>
                <p>De asemenea, ne permite sa asociem orice comentariu pe care il postezi pe website-urile noastre cu numele tau de utilizator.</p>
                <p>Daca nu ai selectat &ldquo;Ramaneti autentificat&rdquo;, acest cookie se va sterge automat cand inchizi browserul sau calculatorul.</p>
                <p><strong>Cookie-uri de marketing / publicitate</strong></p>
                <p>Aceste cookie-uri ne permit sa aflam daca ai vizualizat sau nu o reclama online, care este tipul acesteia si cat timp a trecut de cand ai vazut mesajul publicitar.</p>
                <p>Prin urmare, sunt folosite si pentru a targeta publicitatea online. Putem folosi, de asemenea, cookie-uri apartinand unor terti, pentru o mai buna targetare a publicitatii.</p>
                <p>Aceste cookie-uri sunt anonime, nu stocheaza informatii despre utilizator ci despre continutul vizualizat.</p>
                <p><strong>Contin Cookie-urile date personale?</strong></p>
                <p>Cookie-urile in sine nu solicita informatii cu caracter personal pentru a putea fi utilizate si, in cele mai multe cazuri, nu identifica personal utilizatorii de internet. Datele personale colectate prin utilizarea Cookie-urilor pot fi colectate doar pentru a facilita anumite functionalitati pentru utilizator. Atare date sunt criptate intr-un mod care face imposibil accesul persoanelor neautorizate la ele.</p>
                <p><strong>Stergerea Cookie-urilor</strong></p>
                <p>In general, o aplicatie folosita pentru accesarea paginilor web permite salvarea Cookie-urilor pe terminal in mod implicit. Aceste setari pot fi schimbate in asa fel incat administrarea automata a Cookie-urilor sa fie blocata de browser-ul web sau utilizatorul sa fie informat de fiecare data cand Cookie-uri sunt trimise catre terminalul sau. Informatii detaliate despre posibilitatile si modurile de administrare a Cookie-urilor pot fi gasite in zona de setari a aplicatiei (browser-ului web). Limitarea folosirii Cookie-urilor poate afecta anumite functionalitati ale paginii web.</p>
                <p>Toate browserele moderne ofera optiunea de a schimba setarile cookie-urilor. Acestea pot fi gasite de regula in &ldquo;optiuni&rdquo; sau in meniul de &ldquo;preferinte&rdquo; al browserului tau.</p>
                <ul>
                <li><a href="https://support.google.com/chrome/answer/95647?hl=ro&amp;p=cpn_cookies">Setari Cookie pentru Google Chrome</a></li>
                <li><a href="https://support.mozilla.org/ro/kb/activarea-si-dezactivarea-cookie-urilor">Setari Cookie pentru Mozilla Firefox</a></li>
                <li><a href="https://support.apple.com/kb/PH21411?locale=ro_RO">Setari Cookie pentru Apple Safari</a></li>
                <li><a href="https://support.microsoft.com/ro-ro/help/17442/windows-internet-explorer-delete-manage-cookies">Setari Cookie pentru Microsoft Internet Explorer</a></li>
                </ul>
                <p>Din moment ce instalarea cookie-urilor si a altor sisteme de monitorizare realizate de servicii de la terte parti nu poate fi controlata tehnic de catre proprietar (----------), orice referinta specifica la aceste cookie-uri sau sisteme de monitorizare instalate de terti sunt orientative.</p>
                <p><strong>De ce sunt cookie-urile importante pentru Internet?</strong></p>
                <p>Cookie-urile reprezinta punctul central al functionarii eficiente a Internetului, ajutand la generarea unei experiente de navigare prietenoase si adaptata preferintelor si intereselor fiecarui utilizator. Refuzarea sau dezactivarea cookieurilor poate face unele site-uri imposibil de folosit. Refuzarea sau dezactivarea cookie-urilor nu inseamna ca nu veti mai primi publicitate online ci doar ca aceasta nu va mai putea tine cont de preferintele si interesele dvs, evidentiate prin comportamentul de navigare.</p>
                <p><strong>Securitate si probleme legate de confidentialitate</strong></p>
                <p>Cookieurile NU sunt virusi! Ele folosesc formate tip plain text. Nu sunt alcatuite din bucati de cod asa ca nu pot fi executate nici nu pot auto-rula. In consecinta, nu se pot duplica sau replica pe alte retele pentru a se rula sau replica din nou. Deoarece nu pot indeplini aceste functii, nu pot fi considerate virusi. Cookie-urile pot fi totusi folosite pentru scopuri negative. Deoarece stocheaza informatii despre preferintele si istoricul de navigare al utilizatorilor, atat pe un anume site cat si pe mai multe alte siteuri, cookieurile pot fi folosite ca o forma de Spyware. Multe produse anti-spyware sunt constiente de acest fapt si in mod constant marcheaza cookie-urile pentru a fi sterse in cadrul procedurilor de stergere/scanare anti-virus/anti-spyware.</p>
                <p>In general browserele au integrate setari de confidentialitate care furnizeaza diferite nivele de acceptare a cookieurilor, perioada de valabilitate si stergere automata dupa ce utilizatorul a vizitat un anumit site. Alte aspecte de securitate legate de cookie-uri:</p>
                <p>Deoarece protectia identitatii este foarte valoroasa si reprezinta dreptul fiecarui utilizator de internet, este indicat sa se stie ce eventuale probleme pot crea cookieurile. Pentru ca prin intermediul lor se transmit in mod constant in ambele sensuri informatii intre browser si website, daca un atacator sau persoana neautorizata intervine in parcursul de transmitere a datelor, informatiile continute de cookie pot fi interceptate.</p>
                <p>Desi foarte rar, acest lucru se poate intampla daca browserul se conecteaza la server folosind o retea necriptata (ex: o retea WiFi nesecurizata). Alte atacuri bazate pe cookie implica setari gresite ale cookieurilor pe servere. Daca un website nu solicita browserului sa foloseasca doar canale criptate, atacatorii pot folosi aceasta vulnerabilitate pentru a pacali browserele in a trimite informatii prin intermediul canalelor nesecurizate. Atacatorii utilizeaza apoi informatiile in scopuri de a accesa neautorizat anumite site-uri. Este foarte important sa fiti atenti in alegerea metodei celei mai potrivite de protectie a informatiilor personale.</p>
                <p><strong>Sfaturi pentru o navigare sigura si responsabila, bazata pe cookies.</strong></p>
                <p>Datorita flexibilitatii lor si a faptului ca majoritatea dintre cele mai vizitate site-uri si cele mai mari folosesc cookieuri, acestea sunt aproape inevitabile. Dezactivarea cookie-urilor nu va permite accesul utilizatorului pe site-urile cele mai raspandite si utilizate printre care Youtube, Gmail, Yahoo si altele.</p>
                <p>Iata cateva sfaturi care va pot asigura ca navigati fara griji insa cu ajutorul cookieurilor:</p>
                <p>Particularizati-va setarile browserului in ceea ce priveste cookie-urile pentru a reflecta un nivel confortabil pentru voi al securitatii utilizarii cookie-urilor.</p>
                <p>Daca nu va deranjeaza cookie-urile si sunteti singura persoana care utilizaeaza computerul, puteti seta termene lungi de expirare pentru stocarea istoricului de navigare si al datelorpersonale de acces.</p>
                <p>Daca impartiti accesul la calculator, puteti lua in considerare setarea browserului pentru a sterge datele individuale de navigare de fiecare data cand inchideti browserul. Aceasta este o varianta de a accesa site-urile care plaseaza cookieuri si de a sterge orice informatie de vizitare la inchiderea sesiunii navigare.</p>
                <p>Instalati-va si updatati-va constant aplicatii antispyware.</p>
                <p>Multe dintre aplicatiile de detectare si prevenire a spyware-ului includ detectarea atacurilor pe site-uri. Astfel, impiedica browserul de la a accesa website-uri care ar putea sa exploateze vulnerabilitatile browserului sau sa descarce software periculos. Asigurati-va ca aveti browserul mereu updatat. Multe dintre atacurile bazate pe cookies se realizeaza exploatand punctele slabe ale versiunilor vechi ale browserelor.</p>
                <p>Cookie-urile sunt pretutindeni si nu pot fi evitate daca doriti sa va bucurati de acces pe cele mai bune si cele mai mari site-uri de pe Internet locale sau internationale. Cu o intelegere clara a modului lor de operare si a beneficiilor pe care le aduc, puteti lua masurile necesare de securitate astel incat sa puteti naviga cu incredere pe internet.</p>
                <p>Dezactivarea si refuzul de a primi cookie-uri pot face anumite site-uri impracticabile sau dificil de vizitat si folosit. De asemenea, refuzul de a accepta cookie-uri nu inseamna ca nu veti mai primi/vedea publicitate online.</p>
                <p>Este posibila setarea din browser pentru ca aceste cookie-uri sa nu mai fie acceptate sau poti seta browserul sa accepte cookie-uri de la un site anume. Dar, de exemplu, daca nu esti inregistat folosind cookie-urile, nu vei putea lasa comentarii.</p>
                <p>Toate browserele moderne ofera posibilitatea de a schimba setarile cookie-urilor. Aceste setari se gasesc de regula in optiuni sau in meniul de preferinte al browserului tau. Pentru a intelege aceste setari, urmatoarele linkuri pot fi folositoare, altfel puteti folosi optiunea ajutor a browserului pentru mai multe detalii.</p>
                <p><strong>Linkuri utile</strong></p>
                <p>Daca doriti sa afli mai multe infromatii despre cookie-uri si la ce sunt utilizate, recomandam urmatoarele linkuri:</p>
                <p><a href="https://www.microsoft.com/info/cookies.mspx">Microsoft Cookies guide</a></p>
                <p><a href="http://www.allaboutcookies.org/">All About Cookies</a></p>
                <p><a href="http://www.youronlinechoices.com/ro/">http://www.youronlinechoices.com/ro/</a></p>
                <p>Pentru orice intrebari suplimentare cu privire la modul in care sunt utilizate cookie-urile prin intermediul paginilor noastre de internet, te rugam sa ne trimiti un email la office@vidanjareconstanta.ro</p>
            </Modal.Body>
        </Modal>
    </>
  );
}

export default Cookies