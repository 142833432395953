import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

function Confidentialitate() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
        <li onClick={handleShow}>
            Politica de confidentialitate
        </li>

        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            size="lg"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Politica de confidentialitate</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Conform cerintelor Legii nr. 677/2001 pentru protectia persoanelor cu privire la prelucrarea datelor cu caracter personal si libera circulatie a acestor date, modificata si completata, AQUA SEWER SERVICES GLOBAL SRL are obligatia de a administra in conditii de siguranta si numai pentru scopurile specificate, datele personale pe care ni le furnizati despre dumneavoastra.</p>
                <p>Scopul colectarii datelor este: informarea utilizatorilor privind situatia contului de pe vidanjareconstanta.ro, informarea utilizatorilor/clientilor privind evolutia si starea comenzilor, evaluarea produselor si serviciilor oferite, pentru reclama, marketing si publicitate, statistica.</p>
                <p>vidanjareconstanta.ro nu promoveaza SPAM-ul. Orice utilizator care a furnizat explicit adresa sa de email pe site-ul vidanjareconstanta.ro poate opta pentru stergerea acesteia din baza noastra de date.</p>
                <p>Datele dumneavoastra cu caracter personal vor fi utilizate in scopul pentru care le-ati furnizat, pentru a administra, a sprijini si a evalua serviciile noastre si a preintampina incalcarea securitatii, a legii si a termenilor nostri contractuali.</p>
                <p>De asemenea, AQUA SEWER SERVICES GLOBAL SRL va furniza datele dumneavoastra cu caracter personal altor companii cu care se afla in relatii de parteneriat, dar numai dupa ce ne-am asigurat printr-un contract ca aceste informatii sunt pastrate in siguranta si ca furnizarea acestor informatii personale se face conform legislatiei in vigoare, dupa cum urmeaza: furnizori de servicii de servicii de marketing, telemarketing sau alte servicii, alte companii cu care putem dezvolta programe comune de ofertare pe piata a produselor si serviciilor noastre, asiguratori.</p>
                <p>Informatiile dumneavoastra cu caracter personal pot fi furnizate si catre Parchet, Politie, Instantele judecatoresti si altor organe abilitate ale statului, in baza si in limitele prevederilor legale si ca urmare a unor cereri expres formulate.</p>
                <p>Conform Legii nr. 677/2001, beneficiati de dreptul de acces, de interventie asupra datelor, dreptul de a nu fi supus unei decizii individuale si dreptul de a va adresa justitiei. Totodata, aveti dreptul sa va opuneti prelucrarii datelor personale care va privesc si sa solicitati stergerea datelor cu exceptia situatiilor prevazute de lege.</p>
                <p>Avand in vedere prevederile Regulamentului (UE) 2016/679 al Parlamentului European si al Consiliului din 27 aprilie 2016, privind protectia persoanelor fizice in ceea ce priveste prelucrarea datelor cu caracter personal si libera circulatie a acestor date si abrogarea Directivei 95/46/CE, societatea AQUA SEWER SERVICES GLOBAL SRL doreste sa isi asume angajamentul cu privire la respectarea legislatiei aplicabile domeniului datelor cu caracter personal astfel incat sa ne bucuram in continuare de increderea dumneavoastra.</p>
                <p>Aceasta nota de informare este detalierea a cum, cand si de ce va prelucrăm datele cu caracter personal atunci cand solicitati un produs/serviciu din site-ul web vidanjareconstanta.ro</p>
                <p>Dacă aveti orice intrebare privind modalitatea in care va prelucram datele dumneavoastră cu caracter personal:</p>
                <p>- va puteți adresa Responsabilului cu Protectia datelor cu caracter personal la adresa de e-mail office@vidanjareconstanta.ro sau</p>
                <p>- ne puteti contacta prin posta la adresa Str Tusnad, nr. 16, Sector 2, Bucuresti</p>
                <p><strong>ACORD PRIVIND PRELUCRAREA SI STOCAREA DATELOR CU CARACTER PERSONAL CONFORM REGULAMENTULUI 2016/679/UE aplicabil din 25 Mai 2018</strong></p>
                <p>Societatea Comerciala AQUA SEWER SERVICES GLOBAL SRL, cu sediul social &icirc;n Str Tusnad, nr. 16, Sector 2, Bucuresti, avand numar de ordine in Registrul ComertuluiJ40/11615/2016, cod unic de inregistrare RO36484750 , denumita &icirc;n continuare Prestator si,</p>
                <p>Societatea comerciala / persoana fizica (clientul) accepta prelucrarea si stocarea datelor cu caracter personal comunicate societatii AQUA SEWER SERVICES GLOBAL SRL (cod numeric personal, CIF/CUI, Nr. Reg Comertului, adresa, numar de telefon), in conditiile urmatoare:</p>
                <p>1. Temeiul prelucrarii/stocarii acestor date personale (nume, adresa, CNP, telefon, email, banca, cont bancar) este constituit din contractele semnate si prevederile legale aplicabile.</p>
                <p><em>Tipul de date personale colectate si scopul acestora:</em></p>
                <ol>
                <li>Nume si prenume &ndash; colectam aceste informatii pentru contactarea dvs. ulterioara, in vederea achizitionarii de catre dvs, a produselor / serviciilor noastre, dar si pentru incheierea contractelor de servicii si facturarea acestora. Telefon &ndash; colectam aceste informatii pentru contactarea dvs. ulterioara, in vederea discutiilor detaliate despre serviciile pentru care ne-ati contactat. Email &ndash; colectam aceste informatii pentru contactarea dvs. ulterioara, in vederea discutarii detaliilor detaliate despre serviciile pentru care ne-ati contactat. Nume companie &ndash; pentru incheierea contractelor de servicii si facturarea serviciilor achizitionate.</li>
                </ol>
                <p>Astfel, pentru a facilita desfasurarea activitatilor aflate in legatura cu aceste contracte si in vederea indeplinirii obligatiilor legale/contractuale, Beneficiarul accepta folosirea acestor date cu caracter personal de catre AQUA SEWER SERVICES GLOBAL SRL in anumite activitati specifice, cum ar fi: facturare, avizare, livrare, notificare prin email si sms, acces pe site-ul www.vidanjareconstanta.ro (aria clienti), etc...detinut de AQUA SEWER SERVICES GLOBAL SRL, Nr.Reg.Com.J40/11615/2016, CUI RO36484750 , cu sediul in Str Tusnad, nr. 16, Sector 2, Bucuresti.</p>
                <p>Prin contactarea echipei noastre in vederea contractarii serviciilor pe care le oferim si prin completarea formularelor de inregistrare online (formulat contact, cerere oferta etc/), sunteti de acord ca datele dvs sa intre in baza noastra de date si sa fie folosite in scopurile mentionate.</p>
                <p>2. Durata folosirii si stocarii acestor date cu caracter personal este durata contractuala pana la expirarea obligatiilor contractuale si/sau a termenelor de arhivare ale societatii AQUA SEWER SERVICES GLOBAL SRL</p>
                <p>Ca regula generala, vom stoca datele dvs. cu caracter personal cat timp aveti un cont in platforma AQUA SEWER SERVICES GLOBAL SRL Puteti sa ne solicitati oricand stergerea anumitor informatii sau inchiderea contului si vom da curs acestor solicitari, sub rezerva pastrarii anumitor informatii inclusiv ulterior inchiderii contului, in situasiile in care legislatia aplicabila sau interesele noastre legitime o impun.</p>
                <p>3. Furnizarea datelor personale este:</p>
                <p>- <em>Voluntara</em>, fara a avea in vedere incheierea ulterioara a unei relatii comerciale.</p>
                <p>- <em>Obligatorie</em>, in vederea achizitionarii serviciilor sau produselor noastre si implicit, pentru incheierea unei relatii comerciale si a inregistrarii fiscale a acesteia, inclusiv a facturarii.</p>
                <p>- <em>Optionala</em>, pentru primirea diverselor mesaje publicitare (campanii, promotii), newslettere. In cazul in care nu sunteti de acord cu transmiterea datelor in acest scop, nu inseamna ca nu puteti beneficia de serviciile ori produsele noastre.</p>
                <p>4. Datele cu caracter personal mentionate in actele cu caracter comercial/contabil vor fi stocate pe durata specificata de legislatia in vigoare</p>
                <p>5. Dupa expirarea acestor termene, veti putea solicita in scris (email, postal) stergerea definitiva a acestor date cu caracter personal din programele de evidenta ale societatii AQUA SEWER SERVICES GLOBAL SRL</p>
                <p>6. Aceste date cu caracter personal nu pot fi comunicate de catre AQUA SEWER SERVICES GLOBAL SRL unui tert decat cu confirmarea scrisa a Beneficiarului si/sau in cazurile prevazute expres de legislatia in vigoare. Aceste date sunt listate pe actele societatii AQUA SEWER SERVICES GLOBAL SRL (facturi, contracte, avize, awb-uri,etc...) si sunt stocate pe serverele, soft-urile si site-urile Prestatorului. Prestatorul poate specifica anumite date cu caracter personal in rapoartele impuse de legislatia in vigoare (ex: Jurnale TVA, D394, etc...)</p>
                <p><em><strong>Principalele dumeavoastra drepturi</strong></em></p>
                <p>Conform noului Regulament GDPR, va prezentam in cele de mai jos, care sunt drepturile pe care le aveti in calitate de utilizatori/clienti ai site-ului nostru.</p>
                <p>7. Beneficiarul (clientul) are dreptul de a fi informat asupra prelucarii datelor cu caracter personal de catre Prestator</p>
                <p><em>Dreptul la informare</em> &ndash; se refera la dreptul de a fi informati asupra tuturor aspectelor prezentate anterior.</p>
                <p>8. Beneficiarul (clientul) are dreptul de a primi datele personale intr-un format structurat, utilizat in mod curent, care poate fi citit automat, avand dreptul ca aceste date sa fie transmise direct altui operator, daca acest lucru este fezabil din punct de vedere tehnic.</p>
                <p><em>Dreptul de acces</em> &ndash; aveti dreptul de a obtine confirmarea din partea noastra ca prelucram sau nu datele cu caracter personal care va privesc si, in caz afirmativ, acces la datele respective si la informatii privind modalitatea in care sunt prelucrate datele.</p>
                <p>9. Beneficiarul (clientul) are dreptul de a se opune in scris prelucrarii datelor cu caracter personal (ex: daca scopul prelucrarii este marketingul direct, etc.)</p>
                <p><em>Dreptul la opozitie</em> &ndash; dreptul de a va opune prelucrarii datelor personale daca prelucrarea este necesara pentru indeplinirea unei sarcini care serveste unui interes public. Deasemenea, daca prelucrarea datelor cu caracter personal are drept scop marketingul direct, aveti dreptul de a va opune prelucrarii oricand.</p>
                <p>10. Beneficiarul (clientul) are dreptul de a corecta in scris datele cu caracter personal inexacte</p>
                <p><em>Dreptul la rectificarea datelor</em> &ndash; puteti solicita rectificarea anumitor date personale, in cazul in care acestea sunt incomplete sau inexacte. Astfel, in functie de scopul prelucrarii, puteti completa si apoi transmite datele personale care lipsesc sau nu sunt corecte, fiind posibila chiar completarea unei declaratii pentru conformitate.</p>
                <p>11. Beneficiarul (clientul) are dreptul de a solicita in scris stergerea datele cu caracter persoanal in cazul in care acestea nu mai sunt necesare indeplinirii scopurilor pentru care au fost colectare sau prelucrate si nu exista nici un alt temei juridic de prelucrare a acestor date in continuare</p>
                <p><em>Dreptul la stergerea datelor (&bdquo;dreptul de a fi uitat&rdquo;)</em> &ndash; puteti solicita ca datele sa fie sterse, fara intarzieri nejustificate, in cazul in care apar urmatoarele situatii: datele nu mai sunt necesare pentru indeplinirea scopurilor pentru care au fost colectate sau prelucrate; iti retragi consimtamantul si nu exista niciun alt temei juridic pentru prelucrare; te opui prelucrarii si nu exista motive legitime care sa prevaleze; datele cu caracter personal au fost prelucrate ilegal; datele cu caracter personal trebuie sterse pentru respectarea unei obligatii legale; datele cu caracter personal au fost colectate in legatura cu oferirea de servicii ale societatii informationale.</p>
                <p>12. Beneficiarul (clientul) poate restrictiona prelucrarea datelor cu caracter personal in cazul in care contesta exactitatea datelor, pe o perioada care permite Prestatorului verificarea corectitudinii datelor.</p>
                <p><em>Dreptul la restrictionarea datelor</em> &ndash; aveti dreptul de a cere restrictionarea datelor pe o perioada de timp, de exemplu, in situatia in care s-a luat decizia stergerii datelor dumneavoastra iar dumneavoastra considerati ca va sunt utile in diferite situatii, precum exercitarea unui drept in instanta. Deasemenea, in cazul in care contestati acuratetea datelor ori prelucrarea este ilegala, puteti solicita restrictionarea datelor. Astfel, in urma primirii unei cereri din partea dumneavoastra si in urma verificarii acesteia, vom stopa prelucrarea datelor personale pe o perioada de timp.</p>
                <p>13. Acest acord se aplica asupra oricaror date cu caracter personal comunicate de catre Beneficiar (client) pe durata aplicarii contractelor semnate cu AQUA SEWER SERVICES GLOBAL SRL, atat inaintea acestuia acord cat si dupa semnarea acestui acord, in baza regulamentului 2016/679/UE aplicabil din data de 25 Mai 2018</p>
                <p>14. Beneficiarul (clientul) are dreptul (<em>Dreptul la portabilitatea datelor)</em> de a primi datele personale intr-un format care sa poata fi citit automat si care sa permita ca datele sa fie transmise direct altui operator.</p>
                <p>15. Beneficiarul (clientul) are dreptul <em>de a depune o plangere la Autoritatea Nationala de Supraveghere a Prelucrarii Datelor cu Caracter Personal</em>, atunci cand s-a constatat incalcarea drepturilor cu privire la prelucrarea datelor personale.</p>
                <p>16. Transferul datelor personale in afara UE - Datele dumneavoastra nu vor fi transferate catre tari din afara UE.</p>
                <p>17. Securitatea datelor</p>
                <p>Datele primite de la dvs sunt stocate pe servere care au implementate masuri de siguranta, precum: descoperirea breselor de securitate si a cauzelor acestora, recuperarea datelor personale si limitarea incalcarii securitatii</p>
                <p>In situatia in care care s-a constatat producerea unei incalcari a securitatii datelor dvs personale, vom informa managementul companiei titulare a serverelor pentru a se lua masurile necesare pentru restabilirea securitatii datelor, notificand Autoritatea de Supraveghere cu privire la incidentul de securitate, daca se suspecteaza ca incălcarea securitatii poate să ducă la un risc ridicat pentru drepturile și libertatile persoanelor carora le-a fost afectata securitatea datelor personale.</p>
                <p>Reamintim faptul ca puteti contacta in orice moment Responsabilul AQUA SEWER SERVICES GLOBAL SRL cu protectia datelor prin transmiterea solicitarii dvs prin oricare dintre urmatoarele modalitati:</p>
                <p>&ndash; prin e-mail la adresa: office@vidanjareconstanta.ro sau</p>
                <p>&ndash; prin posta sau curier la adresa: Str Tusnad, nr. 16, Sector 2, Bucuresti &ndash; cu mentiunea in atentia Responsabilului AQUA SEWER SERVICES GLOBAL SRL cu protectia datelor.</p>
                <p>FURNIZOR AQUA SEWER SERVICES GLOBAL SRL</p>
            </Modal.Body>
        </Modal>
    </>
  );
}

export default Confidentialitate