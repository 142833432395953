import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneVolume } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'; 

class QuickContact extends Component {
    render() {
        return (
            <section id="quickcontact">
                <div class="quickcontact_mobile">
                    <a title="Sună acum" aria-label="Whatsapp" data-hover="Telefon" rel="nofollow noopener" href="tel:+40767581843">
                        <FontAwesomeIcon icon={faPhoneVolume} />
                    </a>
                </div>
                <div class="quickcontact_whatsapp">
                    <a title="Sună pe WhatsApp" aria-label="Whatsapp" data-hover="WhatsApp" rel="nofollow noopener" href="https://wa.me/+40767581843">
                        <FontAwesomeIcon icon={faWhatsapp} /> 
                    </a>
                </div>
            </section>
        );
    }
}

export default QuickContact;
