import React, {Component} from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


class Servicii extends Component {
	render() {
		return (
			<>
            <section id="servicii">
                <Container className="titlu_servicii">
                    <Row>
                        <Col xs={12} md={12} lg={12}>
                          <p><span>Gama noastra de servicii</span></p>
                          <h2>Servicii oferite</h2>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row>
                        <Col xs={12} md={6} lg={3} className="serviciu">
                            <h3>Vidanjare</h3>
                            <img alt="" title="" src="images/vidanjare-icon.webp"/>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-right" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z"/>
                            </svg>
                        </Col>
                        <Col xs={12} md={6} lg={3} className="serviciu">
                        <h3>Desfundare canalizare</h3>
                            <img alt="" title="" src="images/desfundare-canalizare-icon.webp"/>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-right" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z"/>
                            </svg>
                        </Col>
                        <Col xs={12} md={6} lg={3} className="serviciu">
                        <h3>Inspectie video CCTV</h3>
                            <img alt="" title="" src="images/inspectie-video-icon.webp"/>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-right" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z"/>
                            </svg>
                        </Col>
                        <Col xs={12} md={6} lg={3} className="serviciu">
                        <h3>Neutralizare deșeuri</h3>
                            <img alt="" title="" src="images/neutralizare-deseuri-icon.webp"/>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-right" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z"/>
                            </svg>
                        </Col>
                    </Row>
                </Container>
            </section>
			</>
		)
	}

}

export default Servicii