import React, {Component} from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Termeni from '../components/Termeni';
import Confidentialitate from '../components/Confidentialitate';
import Cookies from '../components/Cookies';

class Footer extends Component {
	render() {
		return (
			<>
            <footer id="contact">
                <Container>
                    <Row>
                        <Col xs={12} md={12} lg={4} className="footer_block">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-clock-history" viewBox="0 0 16 16">
                                <path d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z"/>
                                <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z"/>
                                <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z"/>
                            </svg>
                            <h4>Telefon</h4>
                            <p>0767 581 843</p>
                        </Col>
                        <Col xs={12} md={12} lg={4} className="footer_logo">
                            <img alt="Vidanjare Constanța" title="Vidanjare Constanța" src="logo.webp"/>
                        </Col>
                        <Col xs={12} md={12} lg={4} className="footer_block">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
                                <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/>
                            </svg>
                            <h4>Email</h4>
                            <p>office@vidanjareconstanta.ro</p>
                        </Col>
                    </Row>
                </Container>

                <Container>
                    <Row>
                        <Col xs={12} md={3} lg={3} className="footer_info">
                            <h5>Despre noi</h5>
                            <p>Suntem o firma cu o vastă experiență în furnizarea serviciilor de vidanjare, desfundare canalizare, inspecție video CCTV și neutralizare deșeuri.</p>
                        </Col>
                        <Col xs={12} md={3} lg={3} className="footer_info">
                            <h5>Servicii oferite</h5>
                            <ul>
                                <li>
                                    <a title="Vidanjare" href="#servicii">Vidanjare</a>
                                </li>
                                <li>
                                    <a title="Desfundare canalizare" href="#servicii">Desfundare canalizare</a>
                                </li>
                                <li>
                                    <a title="Inspecție video canalizare" href="#servicii">Inspecție video canalizare</a>
                                </li>
                                <li>
                                    <a title="Neutralizare deșeuri" href="#servicii">Neutralizare deșeuri</a>
                                </li>
                            </ul>
                        </Col>
                        <Col xs={12} md={3} lg={3} className="footer_info">
                            <h5>Informatii utile</h5>
                            <ul>
                                <Termeni />
                                <Confidentialitate />
                                <Cookies />
                            </ul>
                            <a title="Soluționarea Alternativă a Litigiilor" target="_blank" rel="noreferrer" href="https://anpc.ro/ce-este-sal/"><img alt="Soluționarea Alternativă a Litigiilor" title="Soluționarea Alternativă a Litigiilor" src="SAL.svg"/></a>
                            <a title="Soluționarea online a litigiilor" target="_blank" rel="noreferrer"  href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage"><img alt="Soluționarea online a litigiilor" title="Soluționarea online a litigiilor"  src="SOL.svg"/></a>
                        </Col>
                        <Col xs={12} md={3} lg={3} className="footer_info">
                            <h5>Contact rapid</h5>
                            <ul>
                                <li>
                                    AQUA SEWER SERVICES GLOBAL SRL
                                </li>
                                <li>
                                    CUI: RO36484750 / Reg. Com: J40/11615/2016
                                </li>
                                <li>
                                    Str Tusnad, nr. 16, Sector 2, Bucuresti
                                </li>
                                <li>
                                    Telefon: 0767 581 843
                                </li>
                                <li>
                                    office@vidanjareconstanta.ro
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </footer>
			</>
		)
	}

}

export default Footer