import React, { Component } from 'react'

import Carousel from 'react-bootstrap/Carousel';
import {Animated} from "react-animated-css";
import Button from 'react-bootstrap/Button';

export class Slider extends Component {

  render() {
    return (
      <div>
        <div className="slideshow">
          <Carousel fade>

            <Carousel.Item>
              <img
                className="slider d-block w-100"
                src="images/slide/slide1.webp"
                alt="Drenaj limfatic"
                title="Drenaj limfatic"
				width="1600" 
				height="850"
              />
              <Carousel.Caption>
                <Animated animationIn="bounceInUp" animationOut="fadeOut" animationInDelay="800" animationInDuration="1200" isVisible={true}>
                  <span>Vrei sa golesti fosa septica?</span>
                </Animated>
                <Animated animationIn="bounceInDown" animationOut="fadeOut" animationInDelay="800" animationInDuration="1200" isVisible={true}>
                  <p>Oferim servicii complete de vidanjare cu utilaje moderne la preturi atractive</p>
                </Animated>
                <Animated animationIn="bounceInUp" animationOut="fadeOut" animationInDelay="1200" animationInDuration="2000" isVisible={true}>
                  <a title="Contactează-ne acum" rel="noopener" href="tel:+40767581843"> 
                    <Button variant="outline-light">Contactează-ne acum</Button>
                  </a>
                </Animated>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <img
                className="slider d-block w-100"
                src="images/slide/slide2.webp"
                alt="Cere acum o programare"
                title="Cere acum o programare"
				width="1600" 
				height="850"
              />
              <Carousel.Caption>
                <Animated animationIn="bounceInLeft" animationOut="fadeOut" animationInDelay="600" animationInDuration="1200" isVisible={true}>
                  <span>Ai probleme cu infundarea canalizarii?</span>
                </Animated>
                <Animated animationIn="bounceInRight" animationOut="fadeOut" animationInDelay="1000" animationInDuration="1200" isVisible={true}>
                  <p>Contacteaza-ne acum pentru serviciul de curatare separatoare de grasimi si desfundare conducte de canalizare</p>
                </Animated>
                <Animated animationIn="bounceInUp" animationOut="fadeOut" animationInDelay="1200" animationInDuration="2000" isVisible={true}>
                  <a title="Cere acum o programare" rel="noopener" href="tel:+40767581843"> 
                    <Button variant="outline-light">Cere acum o programare</Button>
                  </a>
                </Animated>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <img
                className="slider d-block w-100"
                src="images/slide/slide3.webp"
                alt="Sună acum pentru o sedinta"
                title="Sună acum pentru o sedinta"
				width="1600" 
				height="850"
              />
              <Carousel.Caption>
                <Animated animationIn="bounceInRight" animationOut="fadeOut" animationInDelay="600" animationInDuration="1200" isVisible={true}>
                  <span>Vidanjare in Constanta</span>
                  <p>Vidanjare pentru toate tipurile de canalizari in Constanta cu utilaje moderne</p>
                </Animated>
                <Animated animationIn="bounceInUp" animationOut="fadeOut" animationInDelay="1200" animationInDuration="2000" isVisible={true}>
                  <a title="Tu ce mai aștepți? Sună acum!" rel="noopener" href="tel:+40767581843"> 
                    <Button variant="outline-light">Tu ce mai aștepți? Sună acum!</Button>
                  </a>
                </Animated>
              </Carousel.Caption>
            </Carousel.Item>

            </Carousel>
     
        </div>
      </div>
    )
  }
}

export default Slider