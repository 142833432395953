import React, {Component} from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';



class Portofoliu extends Component {
	render() {
		return (
			<>
            <section id="portofoliu">
                <Container className="titlu_servicii">
                    <Row>
                        <Col xs={12} md={12} lg={12}>
                          <p><span>Descoperă o parte din lucrările noastre</span></p>
                          <h2>Portofoliu Vidanjare Constanța</h2>
                        </Col>
                    </Row>
                </Container>
                <Container fluid>
                    <Row>
                        <Col xs={12} md={12} lg={12}>
                            <div className="image-row">
                                <div className="image-set">
                                    <a href="images/portofoliu/portofoliu_01.webp" data-lightbox="example-set" data-title="Servicii de vidanjare in Constanta"><img src="images/portofoliu/thumbs/portofoliu_01.webp" alt="" /></a>
                                    <a href="images/portofoliu/portofoliu_02.webp" data-lightbox="example-set" data-title="Servicii de vidanjare in Constanta"><img src="images/portofoliu/thumbs/portofoliu_02.webp" alt="" /></a>
                                    <a href="images/portofoliu/portofoliu_03.webp" data-lightbox="example-set" data-title="Servicii de vidanjare in Constanta"><img src="images/portofoliu/thumbs/portofoliu_03.webp" alt="" /></a>
                                    <a href="images/portofoliu/portofoliu_04.webp" data-lightbox="example-set" data-title="Servicii de vidanjare in Constanta"><img src="images/portofoliu/thumbs/portofoliu_04.webp" alt="" /></a>
                                    <a href="images/portofoliu/portofoliu_05.webp" data-lightbox="example-set" data-title="Servicii de vidanjare in Constanta"><img src="images/portofoliu/thumbs/portofoliu_05.webp" alt="" /></a>
                                    <a href="images/portofoliu/portofoliu_06.webp" data-lightbox="example-set" data-title="Servicii de vidanjare in Constanta"><img src="images/portofoliu/thumbs/portofoliu_06.webp" alt="" /></a>
                                    <a href="images/portofoliu/portofoliu_07.webp" data-lightbox="example-set" data-title="Servicii de vidanjare in Constanta"><img src="images/portofoliu/thumbs/portofoliu_07.webp" alt="" /></a>
                                    <a href="images/portofoliu/portofoliu_08.webp" data-lightbox="example-set" data-title="Servicii de vidanjare in Constanta"><img src="images/portofoliu/thumbs/portofoliu_08.webp" alt="" /></a>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
 
            </section>
			</>
		)
	}

}

export default Portofoliu