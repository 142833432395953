import React, {Component} from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


class Despre extends Component {
	render() {
		return (
			<>
            <section id="despre">
                <Container>
                    <Row>
                        <Col xs={12} md={12} lg={6}>
                            <img alt="Despre Vidanjare Constanța" title="Despre Vidanjare Constanța" src="images/vidanjare.webp"/>
                        </Col>
                        <Col xs={12} md={12} lg={6}>
                            <p><span>Despre Noi</span></p>
                            <h2>Vidanjare Constanța</h2>
                            <p>Suntem o echipa formată din tineri profesioniști ce oferă servicii de vidanjare și desfundare canalizare în Constanța. Cu o vastă experiență în furnizarea serviciilor de vidanjare, echipa noastră dispune de autospeciale moderne pentru vidanjarea foselor septice.</p>
                            <p>Suntem autorizați de către Autoritatea Națională de Mediu în domeniul colectării și epurării apelor uzate</p>
                            <p>Tu ce mai astepti, contacteaza-ne acum la 0767 581 843 sau cere o oferta gratuita pe office@vidanjareconstanta.ro</p>
                            <hr/>
                            <Container className="info_despre">
                                <Row>
                                <Col xs={12} md={12} lg={6}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" fill="currentColor" class="bi bi-truck" viewBox="0 0 16 16">
                                        <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5v-7zm1.294 7.456A1.999 1.999 0 0 1 4.732 11h5.536a2.01 2.01 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456zM12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12v4zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
                                    </svg>
                                    Utilaje moderne pentru vidanjare
                                </Col>
                                <Col xs={12} md={12} lg={6}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" fill="currentColor" class="bi bi-phone-flip" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M11 1H5a1 1 0 0 0-1 1v6a.5.5 0 0 1-1 0V2a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v6a.5.5 0 0 1-1 0V2a1 1 0 0 0-1-1Zm1 13a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-2a.5.5 0 0 0-1 0v2a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-2a.5.5 0 0 0-1 0v2ZM1.713 7.954a.5.5 0 1 0-.419-.908c-.347.16-.654.348-.882.57C.184 7.842 0 8.139 0 8.5c0 .546.408.94.823 1.201.44.278 1.043.51 1.745.696C3.978 10.773 5.898 11 8 11c.099 0 .197 0 .294-.002l-1.148 1.148a.5.5 0 0 0 .708.708l2-2a.5.5 0 0 0 0-.708l-2-2a.5.5 0 1 0-.708.708l1.145 1.144L8 10c-2.04 0-3.87-.221-5.174-.569-.656-.175-1.151-.374-1.47-.575C1.012 8.639 1 8.506 1 8.5c0-.003 0-.059.112-.17.115-.112.31-.242.6-.376Zm12.993-.908a.5.5 0 0 0-.419.908c.292.134.486.264.6.377.113.11.113.166.113.169 0 .003 0 .065-.13.187-.132.122-.352.26-.677.4-.645.28-1.596.523-2.763.687a.5.5 0 0 0 .14.99c1.212-.17 2.26-.43 3.02-.758.38-.164.713-.357.96-.587.246-.229.45-.537.45-.919 0-.362-.184-.66-.412-.883-.228-.223-.535-.411-.882-.571ZM7.5 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1Z"/>
                                    </svg>
                                    Suport rapid 
                                </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </section>
			</>
		)
	}

}

export default Despre