import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import Header from './common/Header';
import Footer from './common/Footer';

import Slider from './components/Slider';
import Statistici from './components/Statistici';
import Despre from './components/Despre';
import Servicii from './components/Servicii';
import Testimoniale from './components/Testimoniale';
import Portofoliu from './components/Portofoliu';
import Call from './components/Call';

import CookieConsent from "react-cookie-consent";
import QuickContact from './components/QuickContact';

function App() {
    
  return (
    <>
      <Header />
      <Slider />
      <Statistici />
      <Despre />
      <Servicii />
      <Testimoniale />
      <Portofoliu />
      <Call />
      <QuickContact />
      <Footer />

      <CookieConsent
          location="bottom"
          buttonText="Accept"
          style={{ background: "#B31312" }}
          buttonStyle={{  background: "#EEE2DE", color: "#111", border: "1px solid #111", fontSize: "1.2rem;" }}
          expires={150}
        >
          Folosim cookie-uri pentru a vă asigura o experiență mai buna pe website-ul nostru. Prin continuarea navigării, vă dați acordul. Vezi <a title="Politica de utilizare cookie" href="/cookies">politica cookies</a> 
        
        </CookieConsent>
    </>
  );
}

export default App;